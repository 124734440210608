import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DropDownList from "../../components/dropDown/DropDownList";
import { Grid, InputBase, InputLabel, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TableForData from "../../components/table/Table";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { padding } from "@mui/system";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  root: {
    "& .MuiFormLabel-root-MuiInputLabel-root": {
      fontFamily: "var(--font-family)",
    },
    "& .MuiInputLabel-shrink": {
      fontFamily: "var(--font-family)",
    },
    fontFamily: "var(--font-family)",
  },
  "label + &": {
    fontFamily: "var(--font-family)",
    marginTop: "10px",
    fontSize: "25px",
    boxShadow: "0 3px 6px #00000029",
    borderRadius: 18,
    // padding: "7px 15px",
  },
  "& .MuiInputBase-input": {
    borderRadius: 18,
    position: "relative",
    backgroundColor: "#ffffff",
    border: "none",
    // fontSize: "var(--font-size)",
    fontSize: "2",
    padding: "7px 26px 7px 15px",
    boxShadow: "0 3px 6px #00000029",
    // Use the system font instead of the default Roboto font.
    fontFamily: "var(--font-family)",
    ".MuiTabs-flexContainer": {
      fontSize: "25px",
      color: "yellow",
    },
    "&:focus": {
      boxShadow: "inset 0 3px 6px #b3b3b373",
      borderRadius: 18,
    },
    "& .MuiInput-input.Mui-disabled": {
      boxShadow: "0 3px 6px #00000029",
    },
  },
  "& .MuiFormLabel-root-MuiInputLabel-root": {
    fontFamily: "var(--font-family)",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabesView({
  onBordValueHandler,
  arrayOfClasses,
  onClassesValueHandler,
  arrayOfSubjet,
  onSubjectValueHandler,
  displayPropForClass,
  displayPropForSubject,
  arrayOfDataFeedToTable,
  isLoading,
  isDisabledClass,
  isDisabledSubject,
  showNote,
  lessonPlanClassNames,
  lessonplanData,
}) {
  const [value, setValue] = React.useState(0);
  const [boardValue, setBoardValue] = React.useState("");
  const [classValue, setClassValue] = React.useState("all");
  const [subjectValue, setSubjectValue] = React.useState("all");
  const [subjectLessonPlan, setSubjectLessonPlan] = React.useState([]);
  const [chapterLessonPlan, setChapterLessonPlan] = React.useState([]);
  const [lessonplanSelectedClass, setLessonplanSelectedClass] = React.useState("");
  const [lessonplanSelectedSubject, setLessonplanSelectedSubject] = React.useState("");
  const [lessonplanSelectedChapter, setLessonplanSelectedChapter] = React.useState("");
  const [lessonplanTopicCount, setLessonplanTopicCount] = React.useState(0);

  useEffect(() => {
    setSubjectValue("all");
  }, [classValue]);

  useEffect(() => {
    setClassValue("all");
  }, [boardValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeForBoard = (event) => {
    onBordValueHandler(event.target.value);
    setBoardValue(event.target.value);
  };

  const handleChangeForClass = (event) => {
    setClassValue(event.target.value);
    onClassesValueHandler(event.target.value);
  };

  const handleChangeForSubject = (event) => {
    onSubjectValueHandler(event.target.value);
    setSubjectValue(event.target.value);
  };

  const handleFilterSubject = (e) => {
    setLessonplanTopicCount(0);
    setLessonplanSelectedClass(e.target.value);
    setLessonplanSelectedSubject("");
    setLessonplanSelectedChapter("");
    // console.log("classe",e.target.value,totalLessonPlan);

    axios.get(`https://dev-api.melzoguru.in/subjects/class/${e.target.value}/gseb`)
      .then((subjectRes) => {
        // console.log("subjectRes",subjectRes)
        if (subjectRes.data.length > 0) {
          setSubjectLessonPlan(subjectRes.data);
        }

      })
  }

  const handleFilterChapter = (e) => {
    setLessonplanTopicCount(0);
    // console.log("handleFilterChapter",e.target)
    setLessonplanSelectedSubject(e.target.value);
    setLessonplanSelectedChapter("");

    axios.get(`https://dev-api.melzoguru.in/chapters/subject/${e.target.value}`)
      .then((chapterRes) => {
        console.log("chapterRes", chapterRes)
        if (chapterRes.data.length > 0) {
          setChapterLessonPlan(chapterRes.data);
        }

      })
  }

  const handleChapterLessonplan = (e) => {
    setLessonplanTopicCount(0);
    setLessonplanSelectedChapter(e.target.value);

    axios.get(`https://dev-api.melzoguru.in/lessonplan/${e.target.value}`)
      .then(LessonRes => {
        // props.setLessonPlanMaster(LessonRes.data)
        // console.log("LessonRes",LessonRes)
        if (LessonRes.data.length > 0) {
          assignLessonModules(LessonRes.data[0].id)
        }
      }).catch(err => console.error(err))
  }

  // Assigning Modules to lesson plan
  const assignLessonModules = (lesson) => {

    axios
      .get(
        `https://dev-api.melzoguru.in/module/${lesson}`
      )
      .then((moduleRes) => {
        // console.log("moduleRes",moduleRes.data);
        assignDataToModule(moduleRes.data);
      })
      .catch((err) => console.log("err", err))
  }

  const assignDataToModule = (data) => {
    // console.log("dataModules",data)
    let topicCount = 0;
    data.map((item, index) => {
      if (item.type === "Topic") {
        topicCount++;
      }
      if (data.length - 1 === index) {
        // console.log("dataModulestopic",topicCount)
        setLessonplanTopicCount(topicCount)
      }
    })
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            sx={{ fontSize: "24px", fontWeight: "800" }}
            label="Content Detail"
            {...a11yProps(0)}
            onClick={() => console.log("selectClass ")}
          />
          <Tab
            sx={{ fontSize: "24px", fontWeight: "800" }}
            label="Lesson plan topic"
            {...a11yProps(1)}
            onClick={() => console.log("selectLessonChapter ")}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <div className="dropDownList">
          <Box sx={{ flexGrow: 1, fontSize: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sm={6} lg={4}>
                <FormControl sx={{ m: 2, fontSize: "20px" }} fullWidth>
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    sx={{ fontSize: "20px" }}
                  >
                    {"Board"}
                  </InputLabel>
                  <Select
                    sx={{
                      fontWeight: 500,
                      color: "gray",
                    }}
                    // defaultValue={"gseb gu"}
                    // inputProps={{
                    //   id: "Select-Bord",
                    // }}
                    value={boardValue}
                    label="Age"
                    name="select bord"
                    onChange={handleChangeForBoard}
                    input={<BootstrapInput />}
                  >
                    <MenuItem
                      key="01"
                      id=" 208209"
                      value="cbse en"
                      sx={{ width: "100%", fontSize: "18px" }}
                    >
                      CBSE
                    </MenuItem>
                    <MenuItem
                      key="03"
                      id=" 208209"
                      value="gseb gu"
                      sx={{ width: "100%", fontSize: "18px" }}
                    >
                      GSEB (Gujarati Medium)
                    </MenuItem>
                    <MenuItem
                      key="02"
                      id=" 208209"
                      value="gseb en"
                      sx={{ width: "100%", fontSize: "18px" }}
                    >
                      GSEB (English Medium)
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12} sm={6} lg={4}>
                <FormControl
                  sx={{ m: 2, display: displayPropForClass }}
                  fullWidth
                  disabled={isDisabledClass}
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    sx={{ fontSize: "20px" }}
                  >
                    Class
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={classValue}
                    label="Class"
                    onChange={handleChangeForClass}
                    input={<BootstrapInput />}
                  >
                    <MenuItem value="all">
                      <em>All</em>
                    </MenuItem>
                    {arrayOfClasses.length > 0
                      ? arrayOfClasses.map((item) => {
                        return (
                          <MenuItem
                            key={item.id.toString()}
                            value={item.id}
                            sx={{ fontSize: "18px" }}
                          >
                            {item.className || item.subjectName}
                          </MenuItem>
                        );
                      })
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sm={6} lg={4}>
                <FormControl
                  className="forDissable"
                  sx={{ m: 2, display: displayPropForSubject, color: "gray" }}
                  fullWidth
                  disabled={isDisabledSubject}
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    sx={{ fontSize: "20px" }}
                  >
                    Subject
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={subjectValue}
                    label="Subject"
                    onChange={handleChangeForSubject}
                    input={<BootstrapInput />}
                  >
                    <MenuItem value="all">
                      <em>All</em>
                    </MenuItem>
                    {arrayOfSubjet.length > 0
                      ? arrayOfSubjet.map((item) => {
                        return (
                          <MenuItem
                            key={item.id.toString()}
                            value={item.id}
                            sx={{ fontSize: "18px" }}
                          >
                            {item.className || item.subjectName}
                          </MenuItem>
                        );
                      })
                      : null}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </div>
        {showNote ? (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 2,
                p: 2,
                width: "100%",
                height: "4rem",
              },
              textAlign: "center",
              paddingBottom: "1rem",
              fontFamily:
                "'Rubik' 'Helvetica Neue' 'Helvetica' 'Arial' 'sans-serif' ",
            }}
          >
            <Paper elevation={3}>
              <h3>Note :</h3>
              <p>
                {" "}
                Please refer the CESE-English medium subjects Physics,
                Chemistry, Mathematics, Biology.
              </p>
            </Paper>
          </Box>
        ) : (
          <TableForData
            arrayOfData={arrayOfDataFeedToTable}
            isLoading={isLoading}
          />
        )}
      </TabPanel>

      <TabPanel value={value} index={1}>
        <div className="dropDownList">
          <Box sx={{ flexGrow: 1, fontSize: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sm={6} lg={3}>
                <FormControl sx={{ m: 2, fontSize: "20px" }} fullWidth>
                  <InputLabel
                    id="class-simple-select-helper-label"
                    sx={{ fontSize: "20px" }}
                  >
                    {"Board"}
                  </InputLabel>
                  <Select
                    sx={{
                      fontWeight: 500,
                      color: "gray",
                    }}
                    value="gseb gu"
                    label="Age"
                    name="select bord"
                    input={<BootstrapInput />}
                  >
                    <MenuItem
                      key="01"
                      id=" 208209"
                      value="gseb gu"
                      sx={{ width: "100%", fontSize: "18px" }}
                    >
                      GSEB (Gujarati Medium)
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} sm={6} lg={3}>

                <FormControl
                  sx={{ m: 2, }}
                  fullWidth
                >
                  <InputLabel
                    id="lesson-simple-select-helper-label"
                    sx={{ fontSize: "20px" }}
                  >
                    Class
                  </InputLabel>
                  <Select
                    labelId="lesson-simple-select-helper-label"
                    id="lesson-simple-select-helper"
                    value={lessonplanSelectedClass}
                    label="lessonClass"
                    onChange={handleFilterSubject}
                    input={<BootstrapInput />}
                  >
                    {lessonPlanClassNames.length > 0
                      ? lessonPlanClassNames.map((item) => {
                        return (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            sx={{ fontSize: "18px" }}
                          >
                            {item.className}
                          </MenuItem>
                        );
                      })
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={3}>
                <FormControl
                  className="forDissable"
                  sx={{ m: 2, color: "gray" }}
                  fullWidth
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    sx={{ fontSize: "20px" }}
                  >
                    Subject
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={lessonplanSelectedSubject}
                    label="Subject"
                    onChange={handleFilterChapter}
                    input={<BootstrapInput />}
                  >
                    {subjectLessonPlan.length > 0
                      ? subjectLessonPlan.map((item) => {
                        return (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            sx={{ fontSize: "18px" }}
                          >
                            {item.subjectName}
                          </MenuItem>
                        );
                      })
                      : null}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} sm={6} lg={3}>
                <FormControl
                  className="forDissable"
                  sx={{ m: 2, color: "gray" }}
                  fullWidth
                >
                  <InputLabel
                    id="demo-simple-select-helper-label"
                    sx={{ fontSize: "20px" }}
                  >
                    Chapter
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={lessonplanSelectedChapter}
                    label="Subject"
                    onChange={handleChapterLessonplan}
                    input={<BootstrapInput />}
                  >
                    {chapterLessonPlan.length > 0
                      ? chapterLessonPlan.map((item) => {
                        return (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            sx={{ fontSize: "18px" }}
                          >
                            {item.chapterName}
                          </MenuItem>
                        );
                      })
                      : null}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} sm={6} lg={3}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: "#000000",
                  }}
                  variant="h6"
                >
                  {"Topic Video Count"}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: "#ed9254",
                    marginLeft: "16px",
                    padding: "8px",
                  }}
                  variant="h5"
                >
                  {lessonplanTopicCount}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </div>
      </TabPanel>

    </Box>
  );
}
