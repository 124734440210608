import React from "react";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import TabesView from "../../components/tabs/Tabs";
import { Box, Grid } from "@mui/material";
import TableForData from "../../components/table/Table";
import baseUrl from "../../constants";

const Home = () => {
  let data;
  let [globleData, setGlobleData] = useState({});
  let [idOfBordForTable, setIdOfBordForTable] = useState("");
  let [idOfClassForTable, setIdOfClassForTable] = useState("");
  let [idOfSubjectForTable, setIdOfSubjectForTable] = useState("");
  let [selectAll, setselectAll] = useState("");
  let [selectAllForSubject, setselectAllSubject] = useState("");
  let [isLoading, setIsLoading] = useState(false);
  let [displayPropForClass, setDisplayPropForClass] = useState("none");
  let [displayPropForSubject, setDisplayPropForSubject] = useState("none");
  let [isDisabledClass, setIsDisabledClass] = useState(false);
  let [isDisabledSubject, setIsDisabledSubject] = useState(true);
  let [showNote, setShowNote] = useState(false);

  let [selectBordValue, setSelectBordValue] = useState("");
  let [selectBordLanguageValue, setSelectBordLanguageValue] = useState("");

  let [selectClass, setSelectClass] = useState([]);

  let [selectClassValue, setselectClassValue] = useState("");
  let [selectSubject, setSelectSubject] = useState([]);

  let [arrayOfDataFeedToTable, setArrayOfDataFeedToTable] = useState([]);

  const [assignedLessonPlanClasses, setAssignedLessonPlanClasses] = useState([]);
  const [lessonplanClassName, setLessonplanClassName] = useState([]);
  const [lessonplanData, setLessonplanData] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseUrl.serverUrl}getContentCount`)
      .then((response) => {
        console.log("response", response.data);
        setGlobleData({ ...response.data });
      })
      .catch((error) => {
        console.log("error", error);
      });

      axios.get(`${baseUrl.serverUrl}class/gseb/gu`).then((classres) => {
        // console.log("classres",classres)
        if(classres.data.length > 0) {
          setLessonplanClassName(classres.data);
        }
      })
  }, []);

  useEffect(() => {
    let isMount = true;

    if (isMount) {
      selectBordValue &&
        selectBordLanguageValue &&
        axios
          .get(
            `${baseUrl.serverUrl}class/${selectBordValue}/${selectBordLanguageValue}`
          )
          .then((response) => {
            // console.log("response of bord last", response.data);
            // console.log("fill the class container done");
            setSelectClass([...response.data]);
            // setIsLoading(false);
          })
          .catch((error) => {
            console.log("error", error);
            // setSelectClass(...response.data);
            setIsLoading(false);
          });
    }

    return () => {
      isMount = false;
      setSelectClass([]);
    };
  }, [selectBordValue, selectBordLanguageValue]);

  

  const onBordValueHandler = (event) => {
    const value = event;
    console.log("value==> onBordValueHandler", value);
    // console.dir(event.target);
    let id;
    setShowNote(false);

    setDisplayPropForSubject("none");
    setIsDisabledClass(true);
    setIsDisabledSubject(true);
    setArrayOfDataFeedToTable([]);

    setIsLoading(true);

    if (value == "cbse en") {
      id = "1";
      setDisplayPropForClass("flex");
    }

    if (value == "gseb gu") {
      id = "3";
      setDisplayPropForClass("flex");
    }
    if (value == "gseb en") {
      setShowNote(true);
      setIsLoading(false);
    }

    setIdOfBordForTable(id);

    if (value == "gseb gu") {
      setSelectBordValue("gseb");
      setSelectBordLanguageValue("gu");
    } else if (value == "gseb en") {
      setSelectBordValue("gseb");
      setSelectBordLanguageValue("en");
    } else if (value == "cbse en") {
      setSelectBordValue("cbse");
      setSelectBordLanguageValue("en");
    }
  };

  const onClassesValueHandler = (event) => {
    console.log("==>event onClassesValueHandler", event);
    const classId = event;
    setDisplayPropForSubject("flex");
    setIsDisabledSubject(true);

    if (classId == "all") {
      setselectAll(classId);
    }
    setArrayOfDataFeedToTable([]);
    setIdOfClassForTable(classId);
    setIsLoading(true);
  };

  useEffect(() => {
    let isMount = true;

    if (isMount) {
      //  setIsLoading(tr);
      idOfClassForTable &&
        axios
          .get(
            `${baseUrl.serverUrl}subjects/class/${idOfClassForTable}`
          )
          .then((response) => {
            // console.log("response", response);
            setSelectSubject([...response.data]);
            // setIsLoading(false);
          })
          .catch((error) => {
            console.log("response", error);
            setIsLoading(false);
          });
    }

    return () => {
      isMount = false;
      setArrayOfDataFeedToTable([]);
    };
  }, [idOfClassForTable, selectAll]);

  const onSubjectValueHandler = (event) => {
    console.log("event.target.value||down");

    const subjectId = event;
    setArrayOfDataFeedToTable([]);
    setIdOfSubjectForTable(subjectId);
    if (subjectId == "all") {
      setselectAllSubject(subjectId);
    }
    setIsLoading(true);
  };

  useEffect(() => {
    let isMount = true;
    console.log("======>", "idOfBordForTable", idOfBordForTable);
    if (isMount) {
      idOfBordForTable &&
        axios
          .get(
            `${baseUrl.serverUrl}getContentDetails/class/${idOfBordForTable}`
          )
          .then((response) => {
            // console.log("response",response.data);
            const data = response.data.content;
            console.log("data|| down", data);
            if (isMount) {
              setArrayOfDataFeedToTable([...data]);
            }

            setIsLoading(false);
            // isDisabledClass(false);
            // isDisabledSubject(false);
            setIsDisabledClass(false);
          })
          .catch((error) => {
            console.log("response", error);
            // alert("error:",error.message)
            setIsLoading(false);
          });
    }

    return () => {
      isMount = false;
      setArrayOfDataFeedToTable([]);
      setIsLoading(true);
    };
  }, [selectBordValue, selectAll]);

  useEffect(() => {
    let isMount = true;

    if (isMount) {
      idOfClassForTable &&
        axios
          .get(
            `${baseUrl.serverUrl}getContentDetails/subject/${idOfClassForTable}`
          )
          .then((response) => {
            // console.log("response", response);
            const data = response.data.content;
            // console.log("data of array of subject|| down", data);
            if (isMount) {
              setArrayOfDataFeedToTable([...data]);
            }

            setIsLoading(false);
            // isDisabledSubject(false);
            setIsDisabledSubject(false);
            setIsDisabledClass(false);
          })
          .catch((error) => {
            console.log("response", error);
            setIsLoading(false);
          });
    }

    return () => {
      isMount = false;
      setArrayOfDataFeedToTable([]);
      setIsLoading(true);
      setIsDisabledClass(true);
    };
  }, [idOfClassForTable, selectAllForSubject]);

  useEffect(() => {
    let isMount = true;

    if (isMount) {
      idOfSubjectForTable &&
        axios
          .get(
            `${baseUrl.serverUrl}getContentDetails/chapter/${idOfSubjectForTable}`
          )
          .then((response) => {
            // console.log("response", response);
            const data = response.data.content;
            // console.log("data of array of subject|| down", data);
            if (isMount) {
              setArrayOfDataFeedToTable([...data]);
            }
            setIsLoading(false);
            setIsDisabledSubject(false);
          })
          .catch((error) => {
            console.log("response", error);
            setIsLoading(false);
          });
    }

    return () => {
      isMount = false;
      setArrayOfDataFeedToTable([]);
      setIsLoading(true);
      setIsDisabledSubject(true);
    };
  }, [idOfSubjectForTable]);

  return (
    <div className="home">
      <div className="homeContainer">
        <Navbar />
        <div className="widgets row1">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={4}>
                <Widget
                  type="3D Model"
                  aynaliticsData={globleData.model}
                  backgroundColor="#6C47FF"
                  id="12ghrf1"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4}>
                <Widget
                  type="Intractice Tools"
                  aynaliticsData={globleData.interactiveTools}
                  backgroundColor="#F96300"
                  id="12ghrf2"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4}>
                <Widget
                  type="Melzo Videos"
                  aynaliticsData={globleData.melzoVideo}
                  backgroundColor="#FFB916"
                  id="12ghrf3"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4}>
                <Widget
                  type="Lesson plan"
                  aynaliticsData={globleData.lessonplan}
                  backgroundColor="#ed9254"
                  id="12ghrf4"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4}>
                <Widget
                  type="questionBank"
                  aynaliticsData={globleData.questions}
                  backgroundColor="#F34971"
                  id="12ghrf5"
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4}>
                <Widget
                  type="vrProjects"
                  aynaliticsData={globleData.vrProjects}
                  backgroundColor="rgb(77, 184, 255)"
                  id="12ghrf6"
                />
              </Grid>
            </Grid>
          </Box>
        </div>
        <div className="listContainer">
          <TabesView
            onBordValueHandler={onBordValueHandler}
            arrayOfClasses={selectClass}
            onClassesValueHandler={onClassesValueHandler}
            arrayOfSubjet={selectSubject}
            onSubjectValueHandler={onSubjectValueHandler}
            displayPropForClass={displayPropForClass}
            displayPropForSubject={displayPropForSubject}
            arrayOfDataFeedToTable={arrayOfDataFeedToTable}
            isLoading={isLoading}
            isDisabledClass={isDisabledClass}
            isDisabledSubject={isDisabledSubject}
            showNote={showNote}
            assignedLessonPlanClasses={assignedLessonPlanClasses}
            lessonPlanClassNames={lessonplanClassName}
            lessonplanData={lessonplanData}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
