import "./widget.scss";
import logo3D from "../../imgs/3D Model.png";
import questionBankLogo from "../../imgs/Quetion Bank.png";
import conceptVideoLogo from "../../imgs/Concept Video.png";
import explaintoryVideo from "../../imgs/Explaintory Video.png";
import interactiveTool from "../../imgs/Interactive Tool.png";
import vrProjects from "../../imgs/VR Projects.png";
import { useEffect } from "react";

const Widget = ({ type, aynaliticsData, backgroundColor ,id,opecity}) => {

  let data;

  useEffect(() => {
    const changeBackground = document.getElementById(id);
    const color=`"${backgroundColor}`;
    // console.log("color",color);
    changeBackground.style.backgroundColor = `${backgroundColor}`;
    // console.log("background Color==>",backgroundColor);
  },[]);

 
  switch (type) {
    case "3D Model":
      data = {
        title: "3D Models",


        icon: <img src={logo3D} alt="product logo" className="img" />,
      };
      break;
    case "Intractice Tools":
      data = {
        title: "Interactive Tools",


        icon: <img src={interactiveTool} alt="product logo" className="img" />,
      };
      break;
    case "Melzo Videos":
      data = {
        title: "Melzo Videos",
     

        icon: <img src={explaintoryVideo} alt="product logo" className="img" />,
      };
      break;
    case "Lesson plan":
      data = {
        title: "Interactive LessonPlans",


        icon: <img src={conceptVideoLogo} alt="product logo" className="img" />,
      };
      break;
    case "questionBank":
      data = {
        title: "Question Bank",


        icon: <img src={questionBankLogo} alt="product logo" className="img" />,
        
      };
      break;
    case "vrProjects":
      data = {
        title: "VR Projects",


        icon: <img src={vrProjects} alt="product logo" className="img" />,
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget" id={id}>
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {aynaliticsData}
        </span>
      </div>
      <div className="right">
        <div className="percentage positive">
          {/* <KeyboardArrowUpIcon />
          {diff} % */}
        </div>
        {data.icon}
      </div>
    </div>
  );
};


export default Widget;
