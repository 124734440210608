import "./navbar.scss";

import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import melzoLogo from "../../imgs/logo-01.png";
import activeUserIcon from "../../imgs/user.png";


const Navbar = () => {

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="items">
          <div className="item">
            <img src={melzoLogo} alt="Melzo logo" className="avatar" />
          </div>
          <div className="item">
            <span className="spanHeading">Live Content Dashboard</span>
          </div>

          <div className="item">
            <div className="groupOfTwo" onClick={() => window.location.href = "/analytics"}>
              <img src={activeUserIcon} alt="" className="activeusers" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
