import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from '@mui/material/Skeleton';
import { useState } from "react";

const TableForData = ({arrayOfData,isLoading}) => {
  
  console.log("arrayOfData of table to feed",arrayOfData);

  const arrayForSkeleton=[25,12,45,78,96,25,28];

  let modelCount = arrayOfData.map(({ model }) => model).reduce((sum, i) => sum + i, 0);
  let melzoVideoCount= arrayOfData.map(({ melzoVideos }) => melzoVideos).reduce((sum, i) => sum + i, 0);
  let lessonplanVideoCount= arrayOfData.map(({ lessonplan }) => lessonplan).reduce((sum, i) => sum + i, 0);
  let questionCount= arrayOfData.map(({ question }) => question).reduce((sum, i) => sum + i, 0);

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow  sx={{ fontSize: "20px" }}>
            <TableCell sx={{ fontSize: "20px" ,fontWeight:"600"}} className="tableCell">Class/Sub</TableCell>
            <TableCell sx={{ fontSize: "20px" ,fontWeight:"600"}}  className="tableCell">3D Models</TableCell>
            <TableCell sx={{ fontSize: "20px" ,fontWeight:"600"}} className="tableCell">Melzo Videos</TableCell>
            <TableCell sx={{ fontSize: "20px" ,fontWeight:"600"}} className="tableCell">Interactive LessonPlans</TableCell>
            <TableCell sx={{ fontSize: "20px" ,fontWeight:"600"}} className="tableCell">Question Bank</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { Boolean(isLoading) ? (
            <> 
            <TableRow key="djfgdsg"  >
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              <TableCell  className="tableCell">{<Skeleton />}</TableCell>
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              </TableRow>
              <TableRow key="djfgds2g"  >
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              <TableCell  className="tableCell">{<Skeleton />}</TableCell>
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              </TableRow>
              <TableRow key="djfg5dsg"  >
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              <TableCell  className="tableCell">{<Skeleton />}</TableCell>
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              </TableRow>
              <TableRow key="djf7gdsg"  >
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              <TableCell  className="tableCell">{<Skeleton />}</TableCell>
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              </TableRow>
              <TableRow key="djfgd5sg"  >
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              <TableCell  className="tableCell">{<Skeleton />}</TableCell>
              <TableCell className="tableCell">{<Skeleton />}</TableCell>
              </TableRow>

              </>) : ((arrayOfData.map(({melzoVideos,lessonplan,explainatoryVideo,model,question,name}) => (
            <TableRow key={name + "hjf45"}  sx={{ fontSize: "20px" }}>
              <TableCell sx={{ fontSize: "20px" }} className="tableCell">{name }</TableCell>
              <TableCell sx={{ fontSize: "20px" }} className="tableCell">{model}</TableCell>
              <TableCell sx={{ fontSize: "20px" }}className="tableCell">{melzoVideos}</TableCell>
              <TableCell sx={{ fontSize: "20px" }} className="tableCell">{Number(lessonplan).toString()}</TableCell>
              <TableCell sx={{ fontSize: "20px" }} className="tableCell">{question}</TableCell>
              </TableRow>)
          ))
         
          )}
          {  arrayOfData.length > 0  &&  (
            <TableRow key={"hj5kf45"}  sx={{ fontSize: "20px" }}>
              <TableCell sx={{ fontSize: "20px" }} className="tableCell">Total</TableCell>
              <TableCell sx={{ fontSize: "20px" }} className="tableCell">{modelCount}</TableCell>
              <TableCell sx={{ fontSize: "20px" }}className="tableCell">{melzoVideoCount}</TableCell>
              <TableCell sx={{ fontSize: "20px" }} className="tableCell">{Number(lessonplanVideoCount).toString()}</TableCell>
              <TableCell sx={{ fontSize: "20px" }} className="tableCell">{questionCount}</TableCell>
              </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableForData;
