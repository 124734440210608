import "./dropdownlist.scss";
import * as React from "react";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import { InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  root: {
    "& .MuiFormLabel-root-MuiInputLabel-root": {
      fontFamily: "var(--font-family)",
    },
    "& .MuiInputLabel-shrink": {
      fontFamily: "var(--font-family)",
    },
    fontFamily: "var(--font-family)",
  },
  "label + &": {
    fontFamily: "var(--font-family)",
    marginTop: "10px",
    boxShadow: "0 3px 6px #00000029",
    borderRadius: 18,
    // padding: "7px 15px",
  },
  "& .MuiInputBase-input": {
    borderRadius: 18,
    position: "relative",
    backgroundColor: "#ffffff",
    border: "none",
    fontSize: "var(--font-size)",
    padding: "7px 26px 7px 15px",
    boxShadow: "0 3px 6px #00000029",
    // Use the system font instead of the default Roboto font.
    fontFamily: "var(--font-family)",
    "&:focus": {
      boxShadow: "inset 0 3px 6px #b3b3b373",
      borderRadius: 18,
    },
    "& .MuiInput-input.Mui-disabled": {
      boxShadow: "0 3px 6px #00000029",
    },
  },
  "& .MuiFormLabel-root-MuiInputLabel-root": {
    fontFamily: "var(--font-family)",
  },
}));

const DropDownList = ({ listOfArray, nameAsId, onClickHandler }) => {
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, fontSize: "20px" }} fullWidth>
        <InputLabel id="demo-simple-select-helper-label">{nameAsId}</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          // value={value}
          label={nameAsId}
          onChange={onClickHandler}
          input={<BootstrapInput />}
        >
          <MenuItem value="all">
            <em>All</em>
          </MenuItem>
          {listOfArray.length > 0
            ? listOfArray.map((item) => {
                return (
                  <MenuItem
                    key={item.id.toString()}
                    value={item.id}
                    sx={{ fontSize: "20px" }}
                  >
                    {item.className || item.subjectName}
                  </MenuItem>
                );
              })
            : null}
        </Select>
      </FormControl>
    </div>
  );
};

export default DropDownList;
