import { Button, Grid, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import constants from "../constants.js"
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function ActiveUsers() {

    const [studentData, setStudentData] = useState([])
    const [teacherData, setTeacherData] = useState([])
    const [allInstitute, setAllInstitute] = useState([])
    const [selectedUserType, setSelectedUserType] = useState("Student")
    const [selectedInstitute, setSelectedInstitute] = useState(1)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [graphData, setGraphData] = useState([])

    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    useEffect(() => {
        getAllInstitute()
    }, [])

    useEffect(() => {
        console.log('studentData', studentData)
        console.log('teacherData', teacherData)
    }, [studentData, teacherData, graphData])


    const getAllInstitute = () => {
        axios.get(`${constants.serverUrl}institute/fetch`).then((response) => {
            setAllInstitute(response.data);
        }).catch(err => console.error(err))
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getAllActiveUsers = (type) => {
        console.log('startDate in getAllActiveUsers', startDate)
        console.log('endDate in getAllActiveUsers', endDate)

        switch (type) {
            case 'Teacher':
                axios.get(`${constants.serverUrl}logging/${selectedInstitute}/Teacher/${startDate}/${endDate}`).then(tDAURes => {

                    setTeacherData(tDAURes.data)
                }).catch(err => console.error(err))
                break;

            case 'Student':
                axios.get(`${constants.serverUrl}logging/${selectedInstitute}/Student/${startDate}/${endDate}`).then(sDAURes => {
                    console.log('sDAURes.data', sDAURes.data)
                    setStudentData(sDAURes.data)

                    // sDAURes.data.map(students => setGraphData([ loginDate: new Date(students.logingDate).toLocaleString()]))
                }).catch(err => console.error(err))
                break;

            default:
                break;
        }
    }

    const setTimePeriod = (e) => {

        let startDate = "";
        let endDate = "";

        let month = parseInt(new Date().getMonth() + 1);
        if (month < 10) {
            month = `0${month}`
        }

        switch (e.target.value) {
            case "today":
                startDate = `${new Date().getFullYear()}-${month}-${new Date().getDate()}`;
                endDate = `${new Date().getFullYear()}-${month}-${new Date().getDate()}`;
                break;

            case "thisweek":
                let previousWeek = new Date().getDate() - 7;
                if (previousWeek < 10) {
                    previousWeek = `0${previousWeek}`
                }

                startDate = `${new Date().getFullYear()}-${month}-${previousWeek}`;
                endDate = `${new Date().getFullYear()}-${month}-${new Date().getDate()}`;
                break;

            case "thismonth":
                // startDate = `2023-02-06`;

                let previousMonth = month - 1;
                if (previousMonth < 10) {
                    previousMonth = `0${previousMonth}`
                }

                startDate = `${new Date().getFullYear()}-${previousMonth}-${new Date().getDate()}`;
                endDate = `${new Date().getFullYear()}-${month}-${new Date().getDate()}`;
                break;

            default:
                break;
        }

        console.log('startDate', startDate)
        console.log('endDate', endDate)
        setStartDate(startDate)
        setEndDate(endDate)
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
                <Button variant='outlined' onClick={() => window.location.href = "/"}>Back</Button>
            </Grid>

            <Grid item xs={6} md={6} lg={3}>
                <Select value={selectedInstitute} onChange={(e) => { console.log('e.target.value', e.target.value); setSelectedInstitute(e.target.value) }} fullWidth>
                    {allInstitute.length > 0 ? (
                        allInstitute.map((institute, index) => {
                            return (
                                <MenuItem key={index} value={institute.id}>{institute.name}</MenuItem>
                            )
                        })
                    ) : (
                        <MenuItem>No Options Available</MenuItem>
                    )}
                </Select>
            </Grid>

            <Grid item xs={6} md={6} lg={3}>
                <Select name='userType' value={selectedUserType} onChange={(e) => setSelectedUserType(e.target.value)} fullWidth>
                    <MenuItem value="Teacher">Teacher</MenuItem>
                    <MenuItem value="Student">Student</MenuItem>
                </Select>
            </Grid>

            <Grid item xs={6} md={6} lg={3}>
                <Select fullWidth defaultValue={"-1"} onChange={setTimePeriod}>
                    <MenuItem value="-1">Select Time</MenuItem>
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="thisweek">This week</MenuItem>
                    <MenuItem value="thismonth">This Month</MenuItem>
                </Select>
            </Grid>

            <Grid item xs={6} md={6} lg={3}>
                <Button variant='outlined' onClick={() => getAllActiveUsers(selectedUserType)}>Apply</Button>
            </Grid>

            <Grid item xs={12} md={8} lg={8}>
                {selectedUserType === "Student" ? (
                    <React.Fragment>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>No</TableCell>
                                        <TableCell>Institute</TableCell>
                                        <TableCell>User Id</TableCell>
                                        <TableCell>Copy user name</TableCell>
                                        <TableCell>Last Login</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                {rowsPerPage > 0 ? (
                                    <TableBody>
                                        {studentData.length > 0 ? (
                                            studentData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sData, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{sData.institute}</TableCell>
                                                        <TableCell>{sData.userName}</TableCell>
                                                        <TableCell>
                                                            <IconButton sx={{ color: "#ed9254" }} onClick={() => { navigator.clipboard.writeText(sData.userName) }} >
                                                                <ContentCopyIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell>{new Date(sData.logingDate).toLocaleString()}</TableCell>
                                                    </TableRow>
                                                )
                                            })) : (
                                            <TableRow>
                                                No Data Available
                                            </TableRow>
                                        )}
                                    </TableBody>
                                ) : (
                                    <TableBody>
                                        {studentData.length > 0 ? (
                                            studentData.map((sData, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{sData.institute}</TableCell>
                                                        <TableCell>{sData.userName}</TableCell>
                                                        <TableCell>
                                                            <IconButton sx={{ color: "#ed9254" }} onClick={() => { navigator.clipboard.writeText(sData.userName) }} >
                                                                <ContentCopyIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell>{new Date(sData.logingDate).toLocaleString()}</TableCell>
                                                    </TableRow>
                                                )
                                            })) : (
                                            <TableRow>
                                                No Data Available
                                            </TableRow>
                                        )}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>

                        {studentData.length > 0 && (
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={studentData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>No</TableCell>
                                    <TableCell>Institute</TableCell>
                                    <TableCell>User Id</TableCell>
                                    <TableCell>Copy User Id</TableCell>
                                    <TableCell>Last Login</TableCell>
                                </TableRow>
                            </TableHead>
                            {rowsPerPage > 0 ? (
                                <TableBody>
                                    {teacherData.length > 0 ? (
                                        (teacherData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((tData, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{tData.institute}</TableCell>
                                                    <TableCell>{tData.userName}</TableCell>
                                                    <TableCell>
                                                        <IconButton sx={{ color: "#ed9254" }} onClick={() => { navigator.clipboard.writeText(tData.userName) }} >
                                                            <ContentCopyIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell>{new Date(tData.logingDate).toLocaleString()}</TableCell>
                                                </TableRow>
                                            )
                                        }))
                                    ) : (
                                        <TableRow>
                                            No Data Available
                                        </TableRow>
                                    )}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {teacherData.length > 0 ? (
                                        (teacherData.map((tData, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{tData.institute}</TableCell>
                                                    <TableCell>{tData.userName}</TableCell>
                                                    <TableCell>
                                                        <IconButton sx={{ color: "#ed9254" }} onClick={() => { navigator.clipboard.writeText(tData.userName) }} >
                                                            <ContentCopyIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell>{new Date(tData.logingDate).toLocaleString()}</TableCell>
                                                </TableRow>
                                            )
                                        }))
                                    ) : (
                                        <TableRow>
                                            No Data Available
                                        </TableRow>
                                    )}
                                </TableBody>
                            )}
                        </Table>
                        {teacherData.length > 0 && (
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={studentData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        )}
                    </React.Fragment>
                )}
            </Grid>

            {/* <Grid item xs={12} md={6} lg={6}>
                {console.log('graphData', graphData)}
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={graphData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="loginDate" />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Login Date" fill="#8884d8" />
                        <Bar dataKey="Name" fill="#00acc1" />
                    </BarChart>
                </ResponsiveContainer>
            </Grid> */}
        </Grid>
    )
}

export default ActiveUsers